<template>
  <section id="login_forget">
    <div class="w1300 cf">

      <div class="login_box fright">
        <form id="form_login_forget" @submit.prevent>

          <p class="forget_description-password active">
            請輸入關於你帳號的電子郵件信箱，認證碼將會寄出。<br>當你收到驗證碼後，你將可以為你的帳號選擇新的密碼。
          </p>

          <LoginInput
            v-model.trim="user.email"
            :item="inputFormat.account"
            :rules="[
              val => !!val || '必填欄位！',
              [emailRegex, '信箱格式錯誤'],
            ]"
          />

          <div class="login_footer">
            <button
              type="button"
              class="btn"
              @click="onSubmit"
            ><h4>SUBMIT</h4></button>
            <router-link to="/login" class="apply_link txt-light_green txt-bold">返回登入</router-link>
          </div>
        </form>
      </div>
      <div class="decorate_img fright"></div>

    </div>
  </section>
</template>

<script>
import '@/assets/scss/login.scss';
import LoginInput from '@/components/form/LoginInput.vue';
import { request, toFormData } from '@/boot/axios';
import { emailRegex } from '@/lib/const';

export default {
  title: '登入',
  name: 'LoginForget.vue',
  data() {
    return {
      emailRegex,
      inputFormat: {
        account: {
          type: 'text',
          name: 'Email',
        },
      },
      user: {
        email: '',
      },
    };
  },
  methods: {
    onSubmit() {
      const errorList = [];
      this.$children.forEach((item) => {
        if (item.$options.name && item.$options.name.match(/^Form/i)) {
          item.test();
          errorList.push(item.error);
        }
      });
      if (errorList.indexOf(true) === -1) {
        request({
          url: 'API',
          method: 'post',
          data: toFormData(this.user),
        }).then(() => {
        });
      } else {
        // console.log('必填欄位尚未完成！');
      }
    },
  },
  components: {
    LoginInput,
  },
};
</script>
